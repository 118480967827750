
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getEventApply, getEventPost } from '@/api/eventPost';
import { downloadFile } from '@/api/attachedFile';
import { UserModule } from '@/store/user';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.initKakao();
    this.getEvent();
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private eventIdx = this.$route.params.idx;

  private loading = true;

  private currentIndex = 1;

  private event = {
    title: '',
    content: '',
    startDate: '',
    endDate: '',
    images: [],
    couponAvailable: false,
  };

  private getEvent() {
    this.loading = true;
    getEventPost(this.eventIdx).then((res) => {
      this.loading = false;
      this.event = res.data;
    }).catch(() => {
      this.$message.error('이벤트를 불러오는데 실패했습니다.');
      this.$router.push({ name: 'EventIndex' });
    });
  }

  private carouselChange() {
  //   this.arrowBtn = document.querySelectorAll('.el-carousel__arrow');
  //   if (this.arrowBtn) {
  //     // NodeList를 forEach로 반복합니다.
  //     this.arrowBtn.forEach(arrow => {
  //       arrow.children[0].addEventListener('click', this.handleArrowLeftClick.bind(this));
  //       arrow.children[1].addEventListener('click', this.handleArrowRightClick.bind(this));
  //     });
  //   }
  //   // 추가로 필요한 로직이 있으면 여기에 작성합니다.
    if (this.event.images.length <= this.currentIndex) {
      this.currentIndex -= 1;
    } else {
      this.currentIndex += 1;
    }
  }

  private handleCouponDownload() {
    if (UserModule.isLogin) {
      getEventApply(this.eventIdx).then((res) => {
        this.$message.success('쿠폰이 발급되었습니다.');
        this.$router.push({ name: 'couponIndex' });
      }).catch((err) => {
        if (err.response.status === 400) this.$message.error(err.response.data.message);
        else this.$message.error('쿠폰발급을 실패했습니다.');
      });
    } else {
      this.$router.push({ name: 'Login' });
    }
  }

  private initKakao() {
    if (!(window as any).kakao || (window as any).kakao.maps) {
      const script = document.createElement('script');
      script.src = `${window.location.protocol}//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.VUE_APP_KAKAO_CLIENT_ID}&libraries=services`;
      document.head.appendChild(script);
    }
  }

  private handleKakaoShare() {
    const baseUrl = process.env.VUE_APP_TARGET;
    const shareUrl = window.location.href;
    (window as any).Kakao.cleanup();
    (window as any).Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY);
    (window as any).Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: this.event.title,
        description: this.event.content,
        imageUrl: this.event.images.length > 0 ? `${baseUrl}/api/attached-file/${this.event.images[0]}` : `${baseUrl}/favicon.ico`,
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      social: {
        likeCount: 0,
        commentCount: 0,
        sharedCount: 0,
      },
      buttons: [
        {
          title: '보러가기',
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
      ],
    });
  }
}
